import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CalendarResponse, District, League, Nullish, School, Sport, State } from 'types'
import { FEATURE_NAME, IState } from '../Filters.model'

const initialState: IState = {
  state: null,
  district: null,
  sport: null,
  isSportChangeable: true,
  school: null,
  isLeague: false,
  league: null,
  date: null,
  calendar: [],
  isCalendarLoaded: false,
}

export const filtersSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<State>) => {
      state.state = action.payload
    },
    setFilterDistrict: (state, action: PayloadAction<Nullish<District>>) => {
      state.district = action.payload
    },
    setFilterSport: (state, action: PayloadAction<Nullish<Sport>>) => {
      state.sport = action.payload
    },
    setFilterSportChangeable: (state, action: PayloadAction<boolean>) => {
      state.isSportChangeable = action.payload
    },
    setFilterSchool: (state, action: PayloadAction<School>) => {
      state.school = action.payload
    },
    setFilterLeague: (state, action: PayloadAction<Nullish<League>>) => {
      state.league = action.payload
    },
    setFilterIsLeague: (state, action: PayloadAction<boolean>) => {
      state.isLeague = action.payload
    },
    setFilterDate: (state, action: PayloadAction<string | null>) => {
      state.date = action.payload
    },
    setFilterCalendar: (state, action: PayloadAction<CalendarResponse>) => {
      state.calendar = action.payload
    },
    setFilterIsCalendarLoaded: (state, action: PayloadAction<boolean>) => {
      state.isCalendarLoaded = action.payload
    },
  },
})

export const {
  setFilterState,
  setFilterDistrict,
  setFilterSchool,
  setFilterSport,
  setFilterSportChangeable,
  setFilterDate,
  setFilterCalendar,
  setFilterLeague,
  setFilterIsLeague,
  setFilterIsCalendarLoaded,
} = filtersSlice.actions
