import styled from 'styled-components'

export const GamesList = styled.div<{ $width: number }>`
  display: grid;
  grid-template-columns: ${({ $width }) => {
    if ($width > 1000) return '1fr 1fr 1fr 1fr'
    if ($width > 800) return '1fr 1fr 1fr'
    if ($width > 500) return '1fr 1fr'
    return '1fr'
  }};
  grid-auto-rows: 1fr;
  column-gap: 6px;
  row-gap: 6px;
`
