import { FiltersCalendarContainer } from 'app/imports/App.components'
import { selectWidgetType } from 'app/imports/App.selectors'
import IconLogo from 'assets/img/logo_small.png'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { LayoutHeaderDistrict } from '../LayoutHeaderDistrict/LayoutHeaderDistrict'
import { LayoutHeaderLeague } from '../LayoutHeaderLeague/LayoutHeaderLeague'
import { LayoutHeaderSchool } from '../LayoutHeaderSchool/LayoutHeaderSchool'
import { LayoutHeaderState } from '../LayoutHeaderState/LayoutHeaderState'
import * as Styled from './LayoutHeader.styled'

export function LayoutHeader() {
  const widgetType = useSelector(selectWidgetType)

  return (
    <Styled.HeaderContainer>
      <Styled.TitleContainer>
        <Styled.LabelContainer>
          <Styled.Label>Presented by CSZ Network</Styled.Label>
          <Styled.Logo src={IconLogo} alt="" />
        </Styled.LabelContainer>

        <Styled.Title>
          {(widgetType === WidgetTypes.State || widgetType === WidgetTypes.StateLeague) && (
            <LayoutHeaderState />
          )}

          {widgetType === WidgetTypes.League && <LayoutHeaderLeague />}

          {widgetType === WidgetTypes.District && <LayoutHeaderDistrict />}

          {widgetType === WidgetTypes.School && <LayoutHeaderSchool />}
        </Styled.Title>
      </Styled.TitleContainer>

      <FiltersCalendarContainer />
    </Styled.HeaderContainer>
  )
}
