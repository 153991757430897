import styled from 'styled-components'

export const Container = styled.div<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  background-color: #ffffff;
  box-shadow: rgb(170, 170, 170) 0 0 4px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
`
