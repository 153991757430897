import { useGetLeaguesQuery, useGetSportsQuery } from 'app/imports/App.hooks'
import { UISelect } from 'features/UI'
import { useAppDispatch } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OnChangeValue } from 'react-select'
import { League, Sport } from 'types'
import { getQueryParams } from 'utils'
import { setFilterDate, setFilterLeague, setFilterSport } from '../../state/Filters.reducer'
import {
  getIsSportChangeable,
  getSelectedLeague,
  getSelectedSport,
} from '../../state/Filters.selectors'
import { FiltersContainer } from './FiltersByLeague.styled'

export function FiltersByLeague() {
  const dispatch = useAppDispatch()
  const { state } = getQueryParams()

  const { data: sports } = useGetSportsQuery(state)
  const { data: leagues } = useGetLeaguesQuery('')

  const selectedLeague = useSelector(getSelectedLeague)
  const selectedSport = useSelector(getSelectedSport)
  const isSportChangeable = useSelector(getIsSportChangeable)

  const sportItems = useMemo(() => {
    if (!sports) {
      return []
    }

    return sports.items.filter((sport) => !sport.isParent)
  }, [sports])

  const handleLeagueChange = (league: OnChangeValue<League, false>) => {
    dispatch(setFilterDate(null))
    dispatch(setFilterLeague(league))
  }

  const handleSportChange = (sport: OnChangeValue<Sport, false>) => {
    dispatch(setFilterDate(null))
    dispatch(setFilterSport(sport))
  }

  if (!sports || !leagues) {
    return null
  }

  return (
    <FiltersContainer $columnCount={isSportChangeable ? 2 : 1}>
      <UISelect<League, false>
        options={leagues.items}
        value={selectedLeague}
        onChange={handleLeagueChange}
        isMulti={false}
        getOptionLabel={(option: League) => option.name}
        getOptionValue={(option: League) => option.id}
        placeholder="Search League"
      />

      {isSportChangeable && (
        <UISelect<Sport, false>
          options={sportItems}
          value={selectedSport}
          onChange={handleSportChange}
          isMulti={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder="Search Sport"
        />
      )}
    </FiltersContainer>
  )
}
