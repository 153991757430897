import { getSelectedLeague, getSelectedSport } from 'app/imports/App.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function LayoutHeaderLeague() {
  const league = useSelector(getSelectedLeague)
  const selectedSport = useSelector(getSelectedSport)

  const title = useMemo(() => {
    if (!league) return ''

    let result = league.name
    if (selectedSport) {
      result += ` ${selectedSport.name}`
    }

    return result
  }, [league, selectedSport])

  if (!league) {
    return null
  }

  return <>{title}</>
}
