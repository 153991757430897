import styled from 'styled-components'

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const GroupTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
`

export const GroupLink = styled.a`
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  color: rgb(28, 129, 230);
  line-height: 1.4;

  &:hover {
    text-decoration: underline;
  }
`

export const GamesList = styled.div<{ $width: number }>`
  display: grid;
  grid-template-columns: ${({ $width }) => {
    if ($width > 999) return '1fr 1fr 1fr 1fr'
    if ($width > 800) return '1fr 1fr 1fr'
    if ($width > 500) return '1fr 1fr'
    return '1fr'
  }};
  gap: 12px;
`
