import { useLayoutWidth } from 'app/imports/App.hooks'
import IconLogoBg from 'assets/img/logo_csz.png'
import { ReactNode } from 'react'
import * as Styled from './LayoutContent.styled'

export function LayoutContent({ children }: { children: ReactNode }) {
  const layoutWidth = useLayoutWidth()

  return (
    <Styled.ContentContainer $bgImage={IconLogoBg} $layoutWidth={layoutWidth}>
      <Styled.Content>{children}</Styled.Content>
    </Styled.ContentContainer>
  )
}
