import { getSelectedSchool, getSelectedSport } from 'app/imports/App.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSchedulesLink } from '../../hooks/useSchedulesLink'
import { useScoresLink } from '../../hooks/useScoresLink'
import * as Styled from './GamesScoresButton.styled'

export function GamesScoresButton() {
  const school = useSelector(getSelectedSchool)
  const selectedSport = useSelector(getSelectedSport)

  const { scoresUrl } = useScoresLink(selectedSport?.id)
  const { schedulesUrl } = useSchedulesLink()

  const buttonTitle = useMemo(() => {
    if (school) {
      return `${school.shortName || school.name} Hub`
    }
    return 'See All Scores'
  }, [school])

  return (
    <Styled.ButtonContainer href={school ? schedulesUrl : scoresUrl} target="_blank">
      <Styled.Button type="button">{buttonTitle}</Styled.Button>
    </Styled.ButtonContainer>
  )
}
