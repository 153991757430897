import { ReactComponent as IconLeftArrow } from 'assets/img/IconLeftArrow.svg'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
`

export const Days = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
`

export const ArrowLeft = styled(IconLeftArrow)`
  cursor: pointer;
  height: 15px;
  fill: rgba(107, 179, 227, 1);
`

export const ArrowRight = styled(IconLeftArrow)`
  cursor: pointer;
  height: 15px;
  fill: rgba(107, 179, 227, 1);
  transform: rotate(180deg);
`
