import styled from 'styled-components'

export const ButtonContainer = styled.a`
  padding: 10px;
  display: flex;
  text-decoration: none;
  width: 100%;
`

export const Button = styled.button`
  background-color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  transition: opacity 200ms ease 0s;
  width: 100%;
  padding: 0;
  border: 0;
  height: 35px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
