import { getSelectedDate, getSelectedDistrict, getSelectedState } from 'app/imports/App.selectors'
import { cszUrl } from 'features/Env'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useScoresLink(sportId?: string) {
  const selectedState = useSelector(getSelectedState)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedDate = useSelector(getSelectedDate)

  const scoresUrl = useMemo(() => {
    const queryParams = [`selectedDate=${selectedDate}`]

    if (selectedState) {
      queryParams.push(`selectedStateId=${selectedState?.id}`)
    }

    if (selectedDistrict) {
      queryParams.push(`selectedCountyId=${selectedDistrict?.id}`)
    }

    if (sportId) {
      queryParams.push(`selectedSportId=${sportId}`)
    }

    queryParams.push('force=true')

    return `${cszUrl}/scores?${queryParams.join('&')}`
  }, [selectedDate, selectedDistrict, selectedState, sportId])

  return { scoresUrl }
}
