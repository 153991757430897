import { CalendarResponse, District, League, Nullish, School, Sport, State } from 'types'

export const FEATURE_NAME = 'filters'

export interface IState {
  state: Nullish<State>
  district: Nullish<District>
  sport: Nullish<Sport>
  isSportChangeable: boolean
  school: Nullish<School>
  isLeague: boolean
  league: Nullish<League>
  date: Nullish<string>
  calendar: CalendarResponse
  isCalendarLoaded: boolean
}

export const CALENDAR_PAGE_SIZE = 7
