import { useGetStateByIdQuery } from 'app/imports/App.hooks'
import { getSelectedDistrict, getSelectedLeague, getSelectedSport } from 'app/imports/App.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getQueryParams } from 'utils'

export function LayoutHeaderState() {
  const { state } = getQueryParams()
  const selectedSport = useSelector(getSelectedSport)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedLeague = useSelector(getSelectedLeague)

  const { data } = useGetStateByIdQuery(state)

  const title = useMemo(() => {
    if (!data) return ''

    let result = ''

    if (selectedDistrict) {
      result += `${selectedDistrict.name}`
    } else if (selectedLeague) {
      result += `${selectedLeague.name}`
    } else {
      result += data.name
    }

    if (selectedSport) {
      result += ` ${selectedSport.name}`
    }

    return result
  }, [data, selectedDistrict, selectedLeague, selectedSport])

  if (!data) {
    return null
  }

  return <>{title}</>
}
