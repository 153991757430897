import styled from 'styled-components'

export const FiltersContainer = styled.div<{ $columnCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 700px;
  margin: 10px auto 0;
`
