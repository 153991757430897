import { useAppDispatch } from 'hooks'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CALENDAR_PAGE_SIZE } from '../../Filters.model'
import { setFilterDate } from '../../state/Filters.reducer'
import { getCalendar, getSelectedDate } from '../../state/Filters.selectors'
import { FiltersCalendarDay } from '../FiltersCalendarDay/FiltersCalendarDay'
import * as Styled from './FiltersCalendar.styled'

export function FiltersCalendar() {
  const dispatch = useAppDispatch()
  const selectedDate = useSelector(getSelectedDate)
  const calendar = useSelector(getCalendar)

  const dateIndex = useMemo(() => {
    return calendar.findIndex((date) => date === selectedDate)
  }, [calendar, selectedDate])

  const dateInterval = useMemo(() => {
    if (calendar.length < CALENDAR_PAGE_SIZE) {
      return { start: 0, end: calendar.length - 1 }
    }

    let start = dateIndex - 3
    let end = dateIndex + 3
    if (start < 0) {
      start = 0
      const diff = CALENDAR_PAGE_SIZE - dateIndex - 1
      end = dateIndex + diff
    }

    if (end > calendar.length - 1) {
      const diff = end - (calendar.length - 1)
      end = calendar.length - 1
      start -= diff
    }

    return { start, end }
  }, [calendar.length, dateIndex])

  const displayedDays = useMemo(() => {
    return calendar.slice(dateInterval.start, dateInterval.end + 1)
  }, [calendar, dateInterval.end, dateInterval.start])

  const handleChangeDate = useCallback(
    (date: string) => {
      dispatch(setFilterDate(date))
    },
    [dispatch],
  )

  const handleGoLeft = () => {
    if (calendar.length === 0) return

    let index = dateIndex - 1
    index = index < 0 ? 0 : index
    const date = calendar[index]
    dispatch(setFilterDate(date))
  }

  const handleGoRight = () => {
    if (calendar.length === 0) return

    let index = dateIndex + 1
    index = index > calendar.length - 1 ? calendar.length - 1 : index
    const date = calendar[index]
    dispatch(setFilterDate(date))
  }

  return (
    <Styled.Container>
      <Styled.ArrowLeft onClick={handleGoLeft} />

      <Styled.Days>
        {displayedDays.map((date) => {
          return (
            <FiltersCalendarDay
              key={date}
              date={date}
              isCurrentDate={date === selectedDate}
              onClick={handleChangeDate}
            />
          )
        })}
      </Styled.Days>

      <Styled.ArrowRight onClick={handleGoRight} />
    </Styled.Container>
  )
}
