import { useLayoutWidth } from 'app/imports/App.hooks'
import { GamesGroupBySport } from '../../Games.model'
import { useScoresLink } from '../../hooks/useScoresLink'
import { GameCard } from '../GameCard/GameCard'
import * as Styled from './GamesGroupSport.styled'

export function GamesGroupSport({ group }: { group: GamesGroupBySport }) {
  const layoutWidth = useLayoutWidth()
  const { scoresUrl } = useScoresLink(group.sportId)

  if (group.games.length === 0) {
    return null
  }

  return (
    <Styled.Group>
      <Styled.GroupHeader>
        <Styled.GroupTitle>{group.sportName}</Styled.GroupTitle>

        <Styled.GroupLink href={scoresUrl} target="_blank">
          See more scores
        </Styled.GroupLink>
      </Styled.GroupHeader>

      <Styled.GamesList $width={layoutWidth}>
        {group.games.map((game) => {
          return <GameCard game={game} key={game.id} />
        })}
      </Styled.GamesList>
    </Styled.Group>
  )
}
