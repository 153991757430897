import { useLayoutWidth } from 'app/imports/App.hooks'
import {
  getIsCalendarEmpty,
  getIsCalendarLoaded,
  getSelectedDate,
  getSelectedDistrict,
  getSelectedSchool,
  getSelectedState,
  selectWidgetType,
} from 'app/imports/App.selectors'
import { UISpinner } from 'features/UI'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { Game } from '../../Games.model'
import { useGetGamesBySchoolQuery } from '../../state/Games.rtk'
import { GameCard } from '../GameCard/GameCard'
import { GamesEmptyLabel } from '../GamesEmptyLabel/GamesEmptyLabel'
import * as Styled from './GamesBySchoolList.styled'

export function GamesBySchoolList() {
  const widgetType = useSelector(selectWidgetType)
  const selectedState = useSelector(getSelectedState)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedSchool = useSelector(getSelectedSchool)
  const selectedDate = useSelector(getSelectedDate)
  const isCalendarEmpty = useSelector(getIsCalendarEmpty)
  const isCalendarLoaded = useSelector(getIsCalendarLoaded)

  const { data: gamesBySchool, isFetching: isGamesBySchoolFetching } = useGetGamesBySchoolQuery(
    {
      stateId: selectedState?.id,
      districtId: selectedDistrict?.id,
      schoolId: selectedSchool?.id,
      date: selectedDate,
    },
    {
      skip:
        widgetType !== WidgetTypes.School ||
        !selectedState ||
        !selectedDistrict ||
        !selectedSchool ||
        !selectedDate,
    },
  )

  const layoutWidth = useLayoutWidth()

  if (isCalendarEmpty && isCalendarLoaded) {
    return <GamesEmptyLabel />
  }

  if (isGamesBySchoolFetching || !gamesBySchool) {
    return (
      <div style={{ marginTop: '6px' }}>
        <UISpinner />
      </div>
    )
  }

  if (gamesBySchool.games.length === 0) {
    return <GamesEmptyLabel />
  }

  return (
    <Styled.GamesList $width={layoutWidth}>
      {gamesBySchool.games.map((game: Game, i) => {
        return <GameCard key={`${game.id}_${i}`} game={game} />
      })}
    </Styled.GamesList>
  )
}
