import { getSelectedSchool, getSelectedSport } from 'app/imports/App.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function LayoutHeaderSchool() {
  const school = useSelector(getSelectedSchool)
  const selectedSport = useSelector(getSelectedSport)

  const title = useMemo(() => {
    if (!school) return ''

    let result = school.shortName
    if (selectedSport) {
      result += ` ${selectedSport.name}`
    }

    return result
  }, [school, selectedSport])

  if (!school) {
    return null
  }

  return <>{title}</>
}
