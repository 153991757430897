import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 5px;
  position: relative;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Title = styled.div`
  white-space: pre-wrap;
  font-size: 34px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1.25;
  text-transform: uppercase;
`

export const LabelContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
`

export const Label = styled.div`
  white-space: pre-wrap;
  font-size: 10px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  line-height: 1;
`

export const Logo = styled.img`
  width: 20px;
`
