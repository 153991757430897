import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'
import { FEATURE_NAME, IState } from '../Filters.model'

export const selectFiltersSlice = (state: RootState): IState => state.app[FEATURE_NAME]

export const getSelectedState = createSelector(selectFiltersSlice, (slice) => slice.state)

export const getSelectedDistrict = createSelector(selectFiltersSlice, (slice) => slice.district)

export const getSelectedLeague = createSelector(selectFiltersSlice, (slice) => slice.league)

export const getIsLeague = createSelector(selectFiltersSlice, (slice) => slice.isLeague)

export const getSelectedSport = createSelector(selectFiltersSlice, (slice) => slice.sport)

export const getIsSportChangeable = createSelector(
  selectFiltersSlice,
  (slice) => slice.isSportChangeable,
)

export const getSelectedSchool = createSelector(selectFiltersSlice, (slice) => slice.school)

export const getSelectedDate = createSelector(selectFiltersSlice, (slice) => slice.date)

export const getCalendar = createSelector(selectFiltersSlice, (slice) => {
  return slice.calendar.map((date) => date[0])
})

export const getIsCalendarEmpty = createSelector(selectFiltersSlice, (slice) => {
  return slice.calendar.length === 0
})

export const getIsCalendarLoaded = createSelector(
  selectFiltersSlice,
  (slice) => slice.isCalendarLoaded,
)
