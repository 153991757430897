import styled from 'styled-components'

export const ContentContainer = styled.div<{ $bgImage: string; $layoutWidth: number }>`
  box-shadow: rgb(170, 170, 170) 1px 1px 2px 0 inset;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(247, 247, 247);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ $layoutWidth }) => {
    if ($layoutWidth > 500) return '375px'
    return '90%'
  }};
  background-image: url(${(props) => props.$bgImage});
`

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
`
