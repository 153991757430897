import { selectWidgetType } from 'app/imports/App.selectors'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { GamesBySchoolList } from '../GamesBySchoolList/GamesBySchoolList'
import { GamesList } from '../GamesList/GamesList'

export function Games() {
  const widgetType = useSelector(selectWidgetType)

  if (widgetType === WidgetTypes.School) {
    return <GamesBySchoolList />
  }

  return <GamesList />
}
