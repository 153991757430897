import {
  getSelectedDistrict,
  getSelectedSchool,
  getSelectedSport,
  getSelectedState,
} from 'app/imports/App.selectors'
import { cszUrl } from 'features/Env'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useSchedulesLink() {
  const selectedState = useSelector(getSelectedState)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedSchool = useSelector(getSelectedSchool)
  const selectedSport = useSelector(getSelectedSport)

  const schedulesUrl = useMemo(() => {
    const queryParams = []

    if (selectedState) {
      queryParams.push(`selectedStateId=${selectedState?.id}`)
    }

    if (selectedDistrict) {
      queryParams.push(`countyId=${selectedDistrict?.id}`)
    }

    if (selectedSchool) {
      queryParams.push(`schoolId=${selectedSchool?.id}`)
    }

    if (selectedSport) {
      queryParams.push(`selectedSportId=${selectedSport.id}`)
    }

    queryParams.push('force=true')

    return `${cszUrl}/schedules?${queryParams.join('&')}`
  }, [selectedDistrict, selectedSchool, selectedSport, selectedState])

  return { schedulesUrl }
}
