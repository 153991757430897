import styled from 'styled-components'

export const NoGamesTitle = styled.div`
  width: 100%;
  text-align: center;
`

export const EmptyContainer = styled.div`
  margin-top: 6px;
`
