import { useEffect, useState } from 'react'
import { LAYOUT_ID } from '../Layout.model'

export function useLayoutWidth() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    function updateWidth() {
      const element = document.getElementById(LAYOUT_ID)
      if (element) {
        setWidth(element.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return width
}
