import dayjs from 'dayjs'
import { CalendarResponse } from '../types'

export function findClosestDate(dates: CalendarResponse): string | null {
  const today = dayjs()

  if (!Array.isArray(dates) || (Array.isArray(dates) && dates.length === 0)) {
    return null
  }

  const firstDateWithScores = dates.find((dateData) => dateData[1])
  const hasDatesWithScores = !!firstDateWithScores
  let closestDate = hasDatesWithScores ? firstDateWithScores : dates[0]

  let minDiff = Math.abs(today.diff(dayjs(dates[0][0]), 'day'))

  dates.forEach((date) => {
    const [dateString, hasScores] = date
    const diff = Math.abs(today.diff(dayjs(dateString), 'day'))

    const scoresFlag = hasDatesWithScores ? hasScores : true

    if (diff < minDiff && scoresFlag) {
      minDiff = diff
      closestDate = date
    }
  })

  return closestDate[0]
}
