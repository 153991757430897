import { rtkApi } from 'app/store/AppStore.rtk'
import dayjs from 'dayjs'
import {
  CalendarOptions,
  CalendarRequest,
  CalendarResponse,
  DistrictListResponse,
  LeagueListResponse,
  School,
  SportListResponse,
  State,
} from 'types'

export const initApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: ['state', 'districts', 'leagues', 'sports', 'school', 'calendar'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getStateById: build.query<State, string>({
        query: (stateId) => `/states/${stateId}`,
        providesTags: ['state'],
      }),

      getSchoolById: build.query<School, string>({
        query: (schoolId) => `/school?id=${schoolId}`,
        providesTags: ['school'],
      }),

      getDistricts: build.query<DistrictListResponse, string>({
        query: (stateId) => {
          return {
            url: `/districts`,
            params: {
              stateId,
              isActive: true,
              page: 1,
              perPage: 500,
            },
          }
        },
        providesTags: ['districts'],
      }),

      getLeagues: build.query<LeagueListResponse, string>({
        query: () => {
          return {
            url: `/leagues`,
            params: {
              isActive: true,
              page: 1,
              perPage: 500,
            },
          }
        },
        providesTags: ['leagues'],
      }),

      getSports: build.query<SportListResponse, string>({
        query: (stateId) => {
          return {
            url: `/sports`,
            params: {
              stateId,
              page: 1,
              perPage: 500,
            },
          }
        },
        providesTags: ['sports'],
      }),

      getCalendarV2: build.query<CalendarResponse, CalendarOptions>({
        query: (request: CalendarOptions) => {
          const { state, district, school, sport, league, isLeague } = request
          const from = dayjs().subtract(6, 'month').format('YYYY-MM-DD')
          const to = dayjs().add(6, 'month').format('YYYY-MM-DD')

          const params: CalendarRequest = {
            from,
            to,
          }

          if (state !== null) {
            params.stateId = state
          }

          if (district !== null) {
            params.districtId = district
          }

          if (school !== null) {
            params.schoolId = school
          }

          if (sport !== null) {
            params.sportId = sport
          }

          if (league !== null) {
            params.leagueId = league
          }

          if (isLeague !== null) {
            params.isLeague = isLeague
          }

          return {
            url: `/games/v2/getCalendar`,
            params,
          }
        },
        providesTags: ['calendar'],
      }),
    }),
  })

export const {
  useGetStateByIdQuery,
  useGetDistrictsQuery,
  useGetLeaguesQuery,
  useGetSportsQuery,
  useGetSchoolByIdQuery,
  useGetCalendarV2Query,
} = initApi
