import { useGetDistrictsQuery, useGetSportsQuery } from 'app/imports/App.hooks'
import { UISelect } from 'features/UI'
import { useAppDispatch } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OnChangeValue } from 'react-select'
import { District, Sport } from 'types'
import { getQueryParams } from 'utils'
import { setFilterDate, setFilterDistrict, setFilterSport } from '../../state/Filters.reducer'
import {
  getIsSportChangeable,
  getSelectedDistrict,
  getSelectedSport,
} from '../../state/Filters.selectors'
import * as Styled from './FiltersByState.styled'

export function FiltersByState() {
  const dispatch = useAppDispatch()
  const { state } = getQueryParams()

  const { data: sports } = useGetSportsQuery(state)
  const { data: counties } = useGetDistrictsQuery(state)

  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedSport = useSelector(getSelectedSport)
  const isSportChangeable = useSelector(getIsSportChangeable)

  const sportItems = useMemo(() => {
    if (!sports) {
      return []
    }

    return sports.items.filter((sport) => !sport.isParent)
  }, [sports])

  const handleDistrictChange = (district: OnChangeValue<District, false>) => {
    dispatch(setFilterDate(null))
    dispatch(setFilterDistrict(district))
  }

  const handleSportChange = (sport: OnChangeValue<Sport, false>) => {
    dispatch(setFilterDate(null))
    dispatch(setFilterSport(sport))
  }

  if (!sports || !counties) {
    return null
  }

  return (
    <Styled.FiltersContainer $columnCount={isSportChangeable ? 2 : 1}>
      <UISelect<District, false>
        options={counties.items}
        value={selectedDistrict}
        onChange={handleDistrictChange}
        isMulti={false}
        getOptionLabel={(option: District) => option.name}
        getOptionValue={(option: District) => option.id}
        placeholder="Search County"
      />

      {isSportChangeable && (
        <UISelect<Sport, false>
          options={sportItems}
          value={selectedSport}
          onChange={handleSportChange}
          isMulti={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder="Search Sport"
        />
      )}
    </Styled.FiltersContainer>
  )
}
