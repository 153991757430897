import * as Styled from './GamesEmptyLabel.styled'

export function GamesEmptyLabel() {
  return (
    <Styled.EmptyContainer>
      <Styled.NoGamesTitle>
        Currently there are no games for this league. Want to add games? Contact us at{' '}
        <a href="mailto:cs@csznetwork.com.">cs@csznetwork.com.</a>.
      </Styled.NoGamesTitle>
    </Styled.EmptyContainer>
  )
}
