import { getSelectedDistrict, getSelectedSport } from 'app/imports/App.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function LayoutHeaderDistrict() {
  const district = useSelector(getSelectedDistrict)
  const selectedSport = useSelector(getSelectedSport)

  const title = useMemo(() => {
    if (!district) return ''

    let result = district.name
    if (selectedSport) {
      result += ` ${selectedSport.name}`
    }

    return result
  }, [district, selectedSport])

  if (!district) {
    return null
  }

  return <>{title}</>
}
