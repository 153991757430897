import { ReactComponent as IconCaretRight } from 'assets/img/caret-right.svg'
import IconSchool from 'assets/img/IconSchool.png'
import { Team } from '../../Games.model'
import * as Styled from './GameCardTeam.styled'

export function GameCardTeam({ team, isHovered }: { team: Team; isHovered: boolean }) {
  return (
    <Styled.Row>
      {team.imgUrl ? (
        <Styled.Avatar src={team.imgUrl} />
      ) : (
        <Styled.DefaultAvatar src={IconSchool} />
      )}

      <Styled.SchoolStandings>
        <Styled.SchoolName $isWon={team.isWon} $isHovered={isHovered}>
          {team.shortName || team.name}
        </Styled.SchoolName>

        <Styled.Standings>
          {team.standings ? `(${team.standings.won}-${team.standings.lose})` : null}
        </Styled.Standings>
      </Styled.SchoolStandings>

      <Styled.WinMark>{team.isWon ? <IconCaretRight /> : null}</Styled.WinMark>

      <Styled.Score $isWon={team.isWon} $isHovered={isHovered}>
        {team.score}
      </Styled.Score>
    </Styled.Row>
  )
}
