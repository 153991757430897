import { useGetCalendarV2Query } from 'app/imports/App.hooks'
import { getIsLeague, getSelectedSport, selectWidgetType } from 'app/imports/App.selectors'
import { UISpinner } from 'features/UI'
import { useAppDispatch } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { findClosestDate } from 'utils'
import {
  setFilterCalendar,
  setFilterDate,
  setFilterIsCalendarLoaded,
} from '../../state/Filters.reducer'
import {
  getCalendar,
  getSelectedDate,
  getSelectedDistrict,
  getSelectedLeague,
  getSelectedSchool,
  getSelectedState,
} from '../../state/Filters.selectors'
import { FiltersCalendar } from '../FiltersCalendar/FiltersCalendar'
import * as Styled from './FiltersCalendarContainer.styled'

export function FiltersCalendarContainer() {
  const dispatch = useAppDispatch()
  const widgetType = useSelector(selectWidgetType)
  const calendar = useSelector(getCalendar)

  const selectedDate = useSelector(getSelectedDate)
  const state = useSelector(getSelectedState)
  const district = useSelector(getSelectedDistrict)
  const isLeague = useSelector(getIsLeague)
  const league = useSelector(getSelectedLeague)
  const school = useSelector(getSelectedSchool)
  const sport = useSelector(getSelectedSport)

  const skipRequest = useMemo(() => {
    if (widgetType === WidgetTypes.State || widgetType === WidgetTypes.StateLeague) {
      return state === null
    }

    if (widgetType === WidgetTypes.League) {
      return state === null || league === null
    }

    if (widgetType === WidgetTypes.District) {
      return state === null || district === null
    }

    if (widgetType === WidgetTypes.School) {
      return state === null || district === null || school === null
    }

    return true
  }, [district, league, school, state, widgetType])

  const { data: calendarData, isFetching } = useGetCalendarV2Query(
    {
      state: state ? state.id : null,
      district: district ? district.id : null,
      school: school ? school.id : null,
      sport: sport ? sport.id : null,
      league: league ? league.id : null,
      isLeague,
    },
    {
      skip: skipRequest,
    },
  )

  useEffect(() => {
    if (calendarData) {
      const closestDate = findClosestDate(calendarData)
      dispatch(setFilterCalendar(calendarData.slice().reverse()))
      dispatch(setFilterIsCalendarLoaded(true))
      dispatch(setFilterDate(closestDate))
    }
  }, [calendarData, dispatch])

  if (isFetching) {
    return (
      <Styled.LoadingContainer>
        <UISpinner />
      </Styled.LoadingContainer>
    )
  }

  if (calendar.length === 0 || !selectedDate) {
    return null
  }

  return <FiltersCalendar />
}
