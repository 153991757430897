import styled from 'styled-components'

export const Card = styled.a<{ $isHovered: boolean }>`
  background: ${({ $isHovered }) => {
    if ($isHovered) return 'rgba(52, 58, 64)'
    return '#fff'
  }};
  color: ${({ $isHovered }) => {
    if ($isHovered) return '#fff'
    return '#000'
  }};
  box-shadow: rgb(170, 170, 170) 1px 1px 4px 0;
  border-radius: 5px;
  padding: 5px;
  min-height: 50px;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
`

export const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0 10px;
`

export const Date = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  padding: 0 5px;
`

export const SportRow = styled.div`
  font-size: 12px;
  line-height: 1.4;
  padding: 0 5px;
`

export const Teams = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
