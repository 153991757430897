import { selectWidgetType } from 'app/imports/App.selectors'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { cszUrl } from 'features/Env'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { Game, sortTeams } from '../../Games.model'
import { GameCardTeam } from '../GameCardTeam/GameCardTeam'
import * as Styled from './GameCard.styled'

dayjs.extend(customParseFormat)

export function GameCard({ game }: { game: Game }) {
  const widgetType = useSelector(selectWidgetType)
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = (value: boolean) => () => {
    setIsHovered(value)
  }

  const date = useMemo(() => {
    if (game.isTBD) return 'TBD'

    if (!game.startTime) return ''

    return dayjs(game.startTime, 'HH:mm:ss').format('h:mm A')
  }, [game])

  const gameUrl = useMemo(() => {
    return `${cszUrl}/scores/view/${game.id}`
  }, [game.id])

  return (
    <Styled.Card
      href={gameUrl}
      target="_blank"
      $isHovered={isHovered}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
    >
      <Styled.HeaderRow>
        <Styled.Date>{date}</Styled.Date>

        {widgetType === WidgetTypes.School && <Styled.SportRow>{game.sport.name}</Styled.SportRow>}
      </Styled.HeaderRow>

      <Styled.Teams>
        {sortTeams(game.teams).map((team, i) => {
          return <GameCardTeam key={`${team.id}_${i}`} team={team} isHovered={isHovered} />
        })}
      </Styled.Teams>
    </Styled.Card>
  )
}
