import {
  getIsCalendarEmpty,
  getIsCalendarLoaded,
  getIsLeague,
  getSelectedDate,
  getSelectedDistrict,
  getSelectedLeague,
  getSelectedSport,
  getSelectedState,
  selectWidgetType,
} from 'app/imports/App.selectors'
import { UISpinner } from 'features/UI'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { GamesGroupBySport } from '../../Games.model'
import { useGetGamesV2Query } from '../../state/Games.rtk'
import { GamesEmptyLabel } from '../GamesEmptyLabel/GamesEmptyLabel'
import { GamesGroupSport } from '../GamesGroupSport/GamesGroupSport'
import * as Styled from './GamesList.styled'

export function GamesList() {
  const widgetType = useSelector(selectWidgetType)
  const selectedState = useSelector(getSelectedState)
  const isCalendarEmpty = useSelector(getIsCalendarEmpty)
  const isCalendarLoaded = useSelector(getIsCalendarLoaded)
  const isLeague = useSelector(getIsLeague)
  const selectedLeague = useSelector(getSelectedLeague)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedSport = useSelector(getSelectedSport)
  const selectedDate = useSelector(getSelectedDate)

  const skipGameByStateRequest = useMemo(() => {
    if (!selectedDate) return true

    if (widgetType === WidgetTypes.State || widgetType === WidgetTypes.StateLeague) {
      return selectedState === null
    }

    if (widgetType === WidgetTypes.League) {
      return selectedState === null || selectedLeague === null
    }

    if (widgetType === WidgetTypes.District) {
      return selectedState === null || selectedDistrict === null
    }

    return true
  }, [selectedDate, selectedDistrict, selectedLeague, selectedState, widgetType])

  const { data: gamesBySport, isFetching } = useGetGamesV2Query(
    {
      stateId: selectedState?.id,
      districtId: selectedDistrict?.id,
      leagueId: selectedLeague?.id,
      sportId: selectedSport?.id,
      isLeague,
      date: selectedDate,
    },
    { skip: skipGameByStateRequest },
  )

  const hasData = useMemo(() => {
    if (!gamesBySport) return false
    if (gamesBySport.length === 0) return false

    const groupWithGames = gamesBySport.find((group) => group.games.length > 0)

    return !!groupWithGames
  }, [gamesBySport])

  if (isCalendarEmpty && isCalendarLoaded) {
    return <GamesEmptyLabel />
  }

  if (isFetching || !gamesBySport) {
    return (
      <div style={{ marginTop: '6px' }}>
        <UISpinner />
      </div>
    )
  }

  if (!hasData) {
    return <GamesEmptyLabel />
  }

  return (
    <Styled.GroupsList>
      {gamesBySport.map((group: GamesGroupBySport) => {
        return <GamesGroupSport key={`${group.sportId}`} group={group} />
      })}
    </Styled.GroupsList>
  )
}
